
import { defineComponent } from "vue";
import ApiService from "@/core/services/ApiService";
import Toast from "@/common/ts/Toast.js";
import { AxiosRequestConfig } from "node_modules/axios";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { setCurrentPageButton } from "@/core/helpers/toolbar";
import Bus from "@/common/ts/Bus";
import "video.js/dist/video-js.css";
import { videoPlayer } from "vue-video-player";
import store from "@/store";
import KTFacePhoto from "@/views/face/FacePhoto.vue";
import { Modal } from "bootstrap";

export default defineComponent({
  name: "kt-widget-9",
  components: {
    videoPlayer,
    KTFacePhoto
  },
  props: {
    widgetClasses: String
  },
  data() {
    return {
      path: "",
      class_id: "",
      course_id: "",
      //课程学习状态
      course_status: "",
      //播放进度
      chapter_id: "",
      section_id: "",
      play_time: "0",
      need_detection: false,
      //人脸照片
      face_photo: "",
      //章节列表
      list_chapter: [],
      //分页
      currentPage: 1,
      perPage: 10,
      //搜索
      searchKey: "",
      //排序
      sortField: "create_time",
      sortOrder: "descending",
      //数据
      totalRows: 0,
      tableData: [],
      //控制参数
      is_jump_next: true,
      is_save_play_time: true,
      //style
      pointer: "none",
      fs: "small",
      playerOptions: {
        // playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
        autoplay: false, // 如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当 true 时，Video.js player 将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            src: "http://wxsnsdy.tc.qq.com/105/20210/snsdyvideodownload?filekey=30280201010421301f0201690402534804102ca905ce620b1241b726bc41dcff44e00204012882540400&bizid=1023&hy=SH&fileparam=302c020101042530230204136ffd93020457e3c4ff02024ef202031e8d7f02030f42400204045a320a0201000400", // 路径
            type: "video/mp4" // 类型
          }
        ],
        poster: "", // 封面地址
        notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖 Video.js 无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, // 时间分割线
          durationDisplay: true, // 总时间
          remainingTimeDisplay: true, // 剩余播放时间
          progressControl: true, // 进度条
          fullscreenToggle: true // 全屏按钮
        }
      }
    };
  },
  methods: {
    // 逻辑说明：    检测 | 保存进度 | 跳转下一节
    // 1、主线播放：  是        是         是
    // 2、主线时点播：否        否         否
    // 3、完成后点播：否        是         是

    // 赋值播放链接
    funcSectionSelect(citem) {
      const id = citem.id;
      const list = this.tableData;
      for (var i = 0; i < list.length; i++) {
        const section_list = (list[i] as any).section_list;
        (list[i] as any).chapter.select = false; //chapter
        for (var j = 0; j < section_list.length; j++) {
          if (section_list[j].id == id) {
            (list[i] as any).section_list[j].select = true;
            (list[i] as any).chapter.select = true; //chapter
            this.playerOptions["sources"][0]["src"] =
              section_list[j].courseware_url;
            this.chapter_id = section_list[j].chapter_id;
            this.section_id = section_list[j].section_id;
            //活跃度检测
            //判断状态
            if (section_list[j].section_status == "02") {
              this.setPointer("auto");
            } else {
              this.setPointer("none");
            }
          } else {
            (list[i] as any).section_list[j].select = false;
          }
        }
      }
      this.tableData = list;
      this.totalRows = this.tableData ? this.tableData.length : 0;
      this.savePlayTime(); //保存进度
    },
    //重播
    handleReplay(citem) {
      if (this.is_save_play_time) {
        //这里应该判断is_save_play_time如果为true，应该保存一次，用于从继续学习到重播的切换
        this.savePlayTime();
      }
      if (this.course_status == "02") {
        this.is_jump_next = true;
        this.is_save_play_time = true;
      } else {
        this.is_jump_next = false;
        this.is_save_play_time = false;
      }
      this.play_time = "0";
      this.funcSectionSelect(citem);
      //(this.$refs["videoPlayer"] as any).player.play();
    },
    //继续学习
    handleContinue() {
      this.is_jump_next = true;
      this.is_save_play_time = true;
      this.loadData();
    },
    //播放
    handleSectionPlay(citem) {
      this.play_time = "0";
      //this.handleSectionSelect(citem);
      this.handleSectionBegin(citem); //add by david 20230301
    },
    // 播放下一个视频，没有则结束，更新状态
    handleNextSectionPlay() {
      if (this.is_jump_next == false) {
        return;
      }
      const chapter_id = this.chapter_id;
      const section_id = this.section_id;
      const list = this.tableData;
      for (var i = 0; i < list.length; i++) {
        const chapter = (list[i] as any).chapter;
        if (chapter.chapter_id == chapter_id) {
          const section_list = (list[i] as any).section_list;
          for (var j = 0; j < section_list.length; j++) {
            if (section_list[j].section_id == section_id) {
              if (j == section_list.length - 1) {
                if (i == list.length - 1) {
                  //课程结束
                  this.setPointer("auto");
                  if (this.course_status != "02") {
                    Toast.show("恭喜，课程结束！");
                    this.course_status = "02";
                  }
                  this.handleReplay((list[0] as any).section_list[0]);
                } else {
                  //转下一章节
                  if ((list[i + 1] as any).section_list.length > 0) {
                    this.handleSectionPlay(
                      (list[i + 1] as any).section_list[0]
                    );
                    break;
                  } else {
                    //特殊情况：章节没有设置小节，应该在list里面去掉
                  }
                }
              } else {
                //转下一小节
                this.handleSectionPlay((list[i] as any).section_list[j + 1]);
                break;
              }
            }
          }
        }
      }
    },
    // 步骤零：loadCourseAnswer
    loadData() {
      const data = {
        class_id: this.class_id,
        course_id: this.course_id
      };
      ApiService.post("MemberCourse/CourseAnswer", data as AxiosRequestConfig)
        .then(({ data }) => {
          if (data.success == true) {
            this.course_status = data.data.course_status;
            this.loadChapterList();
          } else {
            Toast.warning(data.message);
          }
        })
        .catch((error) => {
          Toast.error(error.message);
        });
    },
    // 步骤一：loadData
    loadChapterList() {
      const data = {
        class_id: this.class_id,
        course_id: this.course_id
      };
      ApiService.post("MemberCourse/ChapterList", data as AxiosRequestConfig)
        .then(({ data }) => {
          if (data.success == true) {
            this.tableData = data.data;
            this.totalRows = this.tableData ? this.tableData.length : 0;
            this.getProgress(); //当前播放进度
          } else {
            Toast.warning(data.message);
          }
        })
        .catch((error) => {
          Toast.error(error.message);
        });
    },
    // 步骤二：获取当前播放进度
    getProgress() {
      let data = {
        class_id: this.class_id,
        course_id: this.course_id
      };
      ApiService.post("MemberCourse/ProgressDetail", data as AxiosRequestConfig)
        .then(({ data }) => {
          if (data.success == true) {
            this.convertToList(data.data);
          } else {
            Toast.warning(data.message);
          }
        })
        .catch((error) => {
          Toast.error(error.message);
        });
    },
    // 步骤三：根据进度设置播放时间
    convertToList(progress) {
      const list = this.tableData;
      if (progress) {
        list.forEach((item: any) => {
          item.section_list.forEach((citem) => {
            if (
              citem.chapter_id == progress.chapter_id &&
              citem.section_id == progress.section_id
            ) {
              this.play_time = progress.play_time; //设置播放时间
              this.funcSectionSelect(citem);
              this.handleCheckActivity(); //活跃度检测 add by david 20230301
            }
          });
        });
      } else {
        if (list[0] as any) {
          if ((list[0] as any).section_list) {
            this.handleSectionBegin((list[0] as any).section_list[0]);
          }
        }
      }
    },
    // 步骤四：活跃度检测
    handleCheckActivity() {
      if (this.course_status == "02") {
        return;
      }

      let data = {
        class_id: this.class_id,
        course_id: this.course_id,
        chapter_id: this.chapter_id,
        section_id: this.section_id,
        play_time: this.play_time
      };
      ApiService.post("MemberCourse/CheckActivity", data as AxiosRequestConfig)
        .then(({ data }) => {
          if (data.success == true) {
            //播放
            this.need_detection = false;
            //(this.$refs["videoPlayer"] as any).player.play(); //delete by david 20230224
          } else {
            //Toast.warning(data.message);
            if (data.code == "99") {
              this.need_detection = true;
              const modal = new Modal(
                document.getElementById("kt_modal_face_photo")
              );
              (this.$refs["KTFacePhoto"] as any).initData();
              modal.show();
            } else {
              Toast.warning(data.message);
            }
          }
        })
        .catch((error) => {
          Toast.error(error.message);
        });
    },
    // 开始新的小节
    handleSectionBegin(citem) {
      let data = {
        class_id: this.class_id,
        course_id: this.course_id,
        chapter_id: citem.chapter_id,
        section_id: citem.section_id,
        play_time: this.play_time
      };
      ApiService.post("MemberCourse/SectionBegin", data as AxiosRequestConfig)
        .then(({ data }) => {
          if (data.success == true) {
            this.funcSectionSelect(citem); //再选择播放
            this.handleCheckActivity(); //活跃度检测 add by david 20230301
          } else {
            Toast.warning(data.message);
          }
        })
        .catch((error) => {
          Toast.error(error.message);
        });
    },
    // 小节完成后设置状态
    followSectionComplete() {
      const list = this.tableData;
      list.forEach((item: any) => {
        item.section_list.forEach((citem) => {
          if (
            citem.chapter_id == this.chapter_id &&
            citem.section_id == this.section_id
          ) {
            citem.section_status = "02";
          }
        });
      });
    },
    // 小节结束
    handleSectionEnd() {
      let data = {
        class_id: this.class_id,
        course_id: this.course_id,
        chapter_id: this.chapter_id,
        section_id: this.section_id
      };
      ApiService.post("MemberCourse/SectionEnd", data as AxiosRequestConfig)
        .then(({ data }) => {
          if (data.success == true) {
            this.followSectionComplete();
            this.handleNextSectionPlay();
          } else {
            Toast.warning(data.message);
          }
        })
        .catch((error) => {
          Toast.error(error.message);
        });
    },
    // 保存视频进度
    savePlayTime() {
      if (this.is_save_play_time == false) {
        return;
      }
      let data = {
        class_id: this.class_id,
        course_id: this.course_id,
        chapter_id: this.chapter_id,
        section_id: this.section_id,
        play_time: this.play_time
      };
      ApiService.post("MemberCourse/ProgressUpdate", data as AxiosRequestConfig)
        .then(({ data }) => {
          if (data.success == true) {
            //Toast.warning(data.message);
          } else {
            Toast.warning(data.message);
          }
        })
        .catch((error) => {
          Toast.error(error.message);
        });
    },
    // 设置是否允许拖动条
    setPointer(value) {
      document
        .getElementsByTagName("body")[0]
        .style.setProperty("--pointer", value);
    },
    // 活跃度检测
    handleDetection() {
      let data = {
        class_id: this.class_id,
        process_type: "1", //行为类型 1学时行为类型的活跃度检测 2考试行为类型的活跃度检测
        face_photo: this.face_photo
      };
      ApiService.post("MemberCourse/ClassDetection", data as AxiosRequestConfig)
        .then(({ data }) => {
          if (data.success == true) {
            Toast.warning(data.message);
          } else {
            Toast.warning(data.message);
          }
        })
        .catch((error) => {
          Toast.error(error.message);
        });
    },
    // 播放回调
    onPlayerPlay(player) {
      //判断是否需要活跃度检测
      console.log("player play!", player);
      if (this.need_detection) {
        (this.$refs["videoPlayer"] as any).player.pause();
        this.handleCheckActivity();
      }
    },
    // 暂停回调
    onPlayerPause(player) {
      console.log("player pause!", player);
    },
    // 视频播完回调
    onPlayerEnded(player) {
      console.log("player end!", player);
      this.handleSectionEnd();
    },
    // DOM元素上的readyState更改导致播放停止
    onPlayerWaiting(player) {
      console.log("Player Waiting", player);
    },
    // 已开始播放回调
    onPlayerPlaying(player) {
      console.log("Player Playing", player);
    },
    // 当播放器在当前播放位置下载数据时触发
    onPlayerLoadeddata(player) {
      console.log("Player Loadeddata", player);
    },
    // 当前播放位置发生变化时触发。
    onPlayerTimeupdate(player) {
      this.play_time = player.cache_.currentTime;
      console.log("Player Timeupdate", player);
    },
    // 媒体的readyState为HAVE_FUTURE_DATA或更高
    onPlayerCanplay(player) {
      console.log("player Canplay!", player);
    },
    // 媒体的readyState为HAVE_ENOUGH_DATA或更高。这意味着可以在不缓冲的情况下播放整个媒体文件。
    onPlayerCanplaythrough(player) {
      console.log("player Canplaythrough!", player);
    },
    //播放状态改变回调
    playerStateChanged(playerCurrentState) {
      console.log("player current update state", playerCurrentState);
    },
    //将侦听器绑定到组件的就绪状态。与事件监听器的不同之处在于，如果ready事件已经发生，它将立即触发该函数。。
    playerReadied(player) {
      console.log("example player 1 readied", player);
      player.currentTime(this.play_time);
    },
    handleBack() {
      this.$router.push({ path: this.path });
    },
    initBus() {
      Bus.$on("handleBack", () => {
        this.handleBack();
      });
      Bus.$on("setFacePhoto", (data) => {
        this.face_photo = data;
        this.handleDetection(); //活跃度检测
      });
      Bus.$on("handleCoursePause", () => {
        (this.$refs["videoPlayer"] as any).player.pause();
      });
    }
  },
  beforeUnmount() {
    Bus.$off("setFacePhoto");
    Bus.$off("handleCoursePause");
  },
  mounted() {
    setCurrentPageBreadcrumbs("章节列表", ["课程"]);
    setCurrentPageButton("back", true);
    const params = store.getters.currentSecondaryParams;
    this.path = params.path;
    const row = JSON.parse(params.row); //两个不同路径，统一这里，传递的row不同，但都能取到class_id和course_id
    this.class_id = (row as any).class_id;
    this.course_id = (row as any).course_id;
    this.initBus();
    this.loadData();
    //异常退出一
    window.addEventListener("beforeunload", this.savePlayTime); //函数名称保持一致
  },
  unmounted() {
    window.removeEventListener("beforeunload", this.savePlayTime); //函数名称保持一致
    //异常退出二
    this.savePlayTime();
  }
});
